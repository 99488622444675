import { t } from "i18next"
import search from "../../Assets/Icons/search.svg"
import Tooltip from "@mui/material/Tooltip"
import { Link /* , useSearchParams */, useSearchParams } from "react-router-dom"
import { basename } from "../../Configuration/config"
import Pagination from "../../Components/Pagination/Pagination"
import NotDataFound from "../../Components/NotDataFound/notDataFound"

const CoursesInvoices = ({ setSection, section, changeFilterObj, filterObj2, filterObj, invoices, pageCount }) => {
    return (
        <>
            {
                <section className="componentGlobalWrapper d-flex flex-column">
                    <div>
                        {/* search options */}
                        {/* <div className="searchActionsBar w-100"> */}
                        {/*** search input ***/}
                        {/* <div className="searchActionsBar mt-0 mb-0">
                                <div className="searchInputWrapper">
                                    <div>
                                        <input
                                            type="text"
                                            className="barSearchInput"
                                            name="search"
                                            onChange={changeFilterObj}
                                        />
                                    </div>
                                    <img
                                        src={search}
                                        alt="search-icon"
                                        className="barSearchIcon"
                                    />
                                </div>
                            </div> */}

                        {/* show select */}
                        {/* <div className="perPageContainer">
                                <label
                                    htmlFor="selectShow"
                                    className="perPageLabel"
                                >
                                    {t("show")}
                                </label>
                                <select
                                    name="per_page"
                                    onChange={changeFilterObj}
                                    id={`selectShow`}
                                    className="form-select selectShow"
                                    value={filterObj2?.per_page}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </select>
                            </div> */}
                        {/*** apply search btn  ***/}
                        {/* <button
                                className="applySearchBtn"
                                onClick={() => {}}
                            >
                                {t("search")}
                            </button> */}
                        {/* </div> */}
                        {
                            <div className="tableWrapper">
                                <table className="w-100 table table-striped border-0">
                                    <thead>
                                        <tr>
                                            <th>{t("cost")}</th>
                                            <th>{t("title")}</th>
                                            <th>{t("individual_price")}</th>
                                            <th>{t("username")}</th>
                                            <th>{t("email")}</th>
                                            <th>{t("phone")}</th>
                                            <th>{t("club_name")}</th>
                                            <th>{t("status")}</th>
                                            <th>{t("is_refund")}</th>
                                            <th>{t("actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoices.length > 0 ? (
                                            invoices.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className="desc">
                                                            <div className="descriptionTd">{item?.cost}</div>
                                                        </td>
                                                        <td className="desc">
                                                            <div className="descriptionTd">
                                                                {item?.course_details?.title}
                                                            </div>
                                                        </td>
                                                        <td className="desc">
                                                            <div className="descriptionTd">
                                                                {item?.course_details?.individual_price}
                                                            </div>
                                                        </td>
                                                        <td className="desc">
                                                            <div className="descriptionTd">
                                                                {item?.course_details?.service_provider?.username}
                                                            </div>
                                                        </td>
                                                        <td className="desc">
                                                            <div className="descriptionTd">
                                                                {item?.course_details?.service_provider?.email}
                                                            </div>
                                                        </td>
                                                        <td className="desc">
                                                            <div className="descriptionTd">
                                                                {item?.course_details?.service_provider?.phone}
                                                            </div>
                                                        </td>
                                                        <td className="desc">
                                                            <div className="descriptionTd">
                                                                {item?.course_details?.service_provider?.club?.username}
                                                            </div>
                                                        </td>
                                                        <td className="desc">
                                                            <div className="descriptionTd">{item?.status?.slug}</div>
                                                        </td>
                                                        <td className="desc">
                                                            <div className="descriptionTd">
                                                                {item?.course_details?.is_refund
                                                                    ? "Refund"
                                                                    : "Not Refund"}
                                                            </div>
                                                        </td>

                                                        {/* <td>
                                                            {moment(
                                                                item.created_at
                                                            ).format(
                                                                "DD-MMMM-YYYY"
                                                            )}
                                                        </td> */}
                                                        <td>
                                                            {/* <div>
                                                                {Permissions
                                                                    ?.invoice
                                                                    ?.edit && (
                                                                    <Tooltip
                                                                        title={t(
                                                                            "edit"
                                                                        )}
                                                                    >
                                                                        <Link
                                                                            to={`${basename}/dashboard/invoices/${item.id}/update`}
                                                                        >
                                                                            <img
                                                                                className="iconActions"
                                                                                src={
                                                                                    edit_icon
                                                                                }
                                                                                alt="edit"
                                                                            />
                                                                        </Link>
                                                                    </Tooltip>
                                                                )}
                                                                {Permissions
                                                                    ?.invoice
                                                                    ?.delete && (
                                                                    <Tooltip
                                                                        title={t(
                                                                            "delete"
                                                                        )}
                                                                    >
                                                                        <img
                                                                            data-bs-toggle="modal"
                                                                            onClick={() =>
                                                                                setDeletedId(
                                                                                    item.id
                                                                                )
                                                                            }
                                                                            data-bs-target="#deleteModal"
                                                                            className="iconActions"
                                                                            src={
                                                                                delete_icon
                                                                            }
                                                                            alt="delete"
                                                                        />
                                                                    </Tooltip>
                                                                )}
                                                            </div> */}
                                                            <div>
                                                                {item?.course_details?.is_refund &&
                                                                    item?.status?.slug === "active" && (
                                                                        <Tooltip title={t("action1")}>
                                                                            <Link
                                                                                to={`${basename}/dashboard/invoices/${item.id}/update`}
                                                                            >
                                                                                {/* <img
                                                                                className="iconActions"
                                                                                src={
                                                                                    edit_icon
                                                                                }
                                                                                alt="edit"
                                                                            /> */}
                                                                                action
                                                                            </Link>
                                                                        </Tooltip>
                                                                    )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={3}>
                                                    <NotDataFound />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {/* <DeleteModal
                                    onSubmitDelete={onSubmitDelete}
                                    name="spam"
                                ></DeleteModal> */}
                            </div>
                        }
                    </div>
                    {
                        <div className="d-flex justify-content-end mt-auto">
                            <Pagination
                                pagesNumber={pageCount}
                                page={filterObj.page - 1}
                                pageChangeHandler={() => {
                                    console.log("handlepageChange()")
                                }}
                            />
                        </div>
                    }
                </section>
            }
        </>
    )
}

export default CoursesInvoices
