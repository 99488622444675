import { t } from "i18next"
import React, { useState, useEffect, useContext /* , useRef */ } from "react"
import "../tables.scss"
import { useLocation, useNavigate } from "react-router"
import TitleContext from "../../Contexts/TitleContext"
import edit_icon from "../../Assets/Icons/edit.svg"
import delete_icon from "../../Assets/Icons/delete.svg"
import search from "../../Assets/Icons/search.svg"
import { basename } from "../../Configuration/config"
import Tooltip from "@mui/material/Tooltip"
import { Link /* , useSearchParams */, useSearchParams } from "react-router-dom"
import DeleteModal from "../../Utilities/deleteModal"
import i18n from "../../i18n"
import moment from "moment"
import "../forms.scss"
import PermissionsContext from "../../Contexts/permissionsContext"
import { getInvoices } from "../../Apis/invoices"
import Pagination from "../../Components/Pagination/Pagination"
import { dateLanguage } from "../../Utilities/dateLanguage"
import NotDataFound from "../../Components/NotDataFound/notDataFound"
import CoursesInvoices from "./CoursesInvoices"
import Competition from "./Competition"
import Promote from "./Promote"
import CourseSubscribtion from "./CourseSubscribtion"

// CONST
const sections = [
    {
        id: 2,
        slug: "Course",
        name: "Course",
        created_at: null,
    },
    {
        id: 3,
        slug: "Competition",
        name: "Competition",
        created_at: null,
    },
    {
        id: 4,
        slug: "Promote",
        name: "Promote",
        created_at: null,
    },
    {
        id: 5,
        slug: "CourseSubscribtion",
        name: "Course Subscribtion",
        created_at: null,
    },
]

export default function Invoices() {
    let { Permissions } = useContext(PermissionsContext)
    console.log("Permissions", Permissions)
    const [searchParams] = useSearchParams()
    console.log(searchParams.get("section"))
    const [section, setSection] = useState(sections[0]?.slug)
    const [invoices, setInvoices] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [total, setTotal] = useState([])
    const [deletedId, setDeletedId] = useState("")
    const { pathname } = useLocation()
    const { changeTitle } = useContext(TitleContext)
    const [filterObj, setfilterObj] = useState({
        per_page: 10,
        page: 1,
        search: "",
        section: section,
    })
    const [filterObj2, setfilterObj2] = useState({
        per_page: 10,
        search: "",
    })
    const [pageCount, setPageCount] = useState("")
    const navigate = useNavigate()

    // function getInvoices(obj) {
    //     let filter = { ...obj }
    //     let params = `per_page=${filter.per_page}&search=${filter.search}&page=${filter.page}&spam_section=${filter.section}`
    //     console.log(params)
    //     getInvoices(params).then((res) => {
    //         setInvoices(res.data.data)
    //         console.log(res)
    //         let total = res.data.pagination.total
    //         setTotal(total)
    //         setPageCount(Math.ceil(total / filter.per_page))
    //     })
    // }

    useEffect(() => {
        window.scrollTo(0, 0)

        // eslint-disable-next-line
    }, [pathname])

    useEffect(() => {
        dateLanguage(i18n.language)
        // eslint-disable-next-line
    }, [i18n.language])

    useEffect(() => {
        changeTitle(
            t(`invoices`),
            Permissions?.invoices?.create && {
                url: "invoices/create",
                text: t("add-invoices"),
                canAdd: Permissions?.invoices?.create,
            }
        )
    }, [])

    useEffect(() => {
        // getAllInvoicesSections().then((res) => {
        //     // setSections(res.data.data)
        //     console.log(res.data.data)
        // })

        getInvoices(section, currentPage).then((res) => {
            console.log("invoices", res.data?.data)
            setInvoices(res.data?.data)
            // let total = res.data?.pagination?.total
            // setTotal(total)
            // setPageCount(Math.ceil(total / filter.per_page))
        })
        // eslint-disable-next-line
    }, [section, currentPage])

    // async function onSubmitDelete() {
    //     return await deleteSpam(deletedId)
    //         .then((res) => {
    //             getInvoices(filterObj)
    //             return "done"
    //         })
    //         .catch((err) => {
    //             console.log(err.response.data.message)
    //             return err.response.data.message
    //         })
    // }

    function changeFilterObj(e) {
        let thisObj = {
            ...filterObj2,
        }
        thisObj[e.target.name] = e.target.value.trim()
        setfilterObj2(thisObj)
    }
    function handelSetSection(value) {
        setSection(value)
    }

    // function getFiltered() {
    //     let thisObj = { ...filterObj }
    //     thisObj.page = 1
    //     thisObj.per_page = filterObj2.per_page
    //     thisObj.search = filterObj2.search
    //     setfilterObj(thisObj)
    //     getInvoices(thisObj)
    // }

    // const handlepageChange = async (data) => {
    //     let filter = { ...filterObj }
    //     filter.page = data.selected + 1
    //     setfilterObj(filter)
    //     getInvoices(filter)

    //     let thisObj2 = { ...filterObj2 }
    //     thisObj2.per_page = filter.per_page
    //     thisObj2.search = filter.search
    //     setfilterObj2(thisObj2)
    // }

    // function filterByStatus(value) {
    //     setSection(value)
    //     let obj = { ...filterObj }
    //     console.log(obj)
    //     obj.section = value
    //     obj.page = 1
    //     setfilterObj(obj)
    //     getInvoices(obj)

    //     let thisObj2 = { ...filterObj2 }
    //     thisObj2.per_page = obj.per_page
    //     thisObj2.search = obj.search
    //     setfilterObj2(thisObj2)
    //     navigate(`${basename}/dashboard/invoices?type=${value}`)
    // }

    return (
        <>
            <div className="tabs-container nav nav-tabs d-flex align-items-center" id="myTab" role="tablist">
                {sections?.map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            {index ? <span className="separator"></span> : ""}
                            <div
                                onClick={() => setSection(item.slug)}
                                className={`tablinks ${section === item.slug && `active`}`}
                                id={`${item.slug}-tab`}
                                data-bs-toggle="tab"
                                data-bs-target={`#${item.slug}`}
                            >
                                {item?.name}{" "}
                                <span className="tabcounter">
                                    {section === item.slug && "(" + invoices.length + ")"}{" "}
                                </span>
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>

            {section === "Course" && (
                <CoursesInvoices
                    setSection={handelSetSection}
                    section={section}
                    changeFilterObj={changeFilterObj}
                    filterObj2={filterObj2}
                    filterObj={filterObj}
                    invoices={invoices}
                    pageCount={pageCount}
                />
            )}

            {section === "Competition" && (
                <Competition
                    setSection={handelSetSection}
                    section={section}
                    changeFilterObj={changeFilterObj}
                    filterObj2={filterObj2}
                    filterObj={filterObj}
                    invoices={invoices}
                    pageCount={pageCount}
                />
            )}
            {section === "Promote" && (
                <Promote
                    setSection={handelSetSection}
                    section={section}
                    changeFilterObj={changeFilterObj}
                    filterObj2={filterObj2}
                    filterObj={filterObj}
                    invoices={invoices}
                    pageCount={pageCount}
                />
            )}
            {section === "CourseSubscribtion" && (
                <CourseSubscribtion
                    setSection={handelSetSection}
                    section={section}
                    changeFilterObj={changeFilterObj}
                    filterObj2={filterObj2}
                    filterObj={filterObj}
                    invoices={invoices}
                    pageCount={pageCount}
                />
            )}
        </>
    )
}
