import React, { useContext, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { basename } from '../../Configuration/config';
import './Aside.scss';
import { t } from 'i18next';
import overview from '../../Assets/Icons/overview.svg';
import positions from '../../Assets/Icons/positions.svg';
import settings from '../../Assets/Icons/settings.svg';
import levels from '../../Assets/Icons/levels.svg';
import foots from '../../Assets/Icons/foots.svg';
import blogs from '../../Assets/Icons/blogs.svg';
import admins from '../../Assets/Icons/admins.svg';
import spam from '../../Assets/Icons/spam.svg';
import app_users from '../../Assets/Icons/app_users.svg';
import logo from '../../Assets/logo remontada.svg';
import report from '../../Assets/Icons/report.svg';
import ved from '../../Assets/Icons/ved.svg';
import courses from '../../Assets/Icons/courses.svg';
import fee from '../../Assets/Icons/fee.svg';
import contact from '../../Assets/Icons/contact.svg';
import stickers from '../../Assets/Icons/stickers.svg';
import PermissionsContext from '../../Contexts/permissionsContext';
import pro from '../../Assets/Icons/pro.svg';
import deleteUser from '../../Assets/Icons/deleteUser.svg';
import invoices from '../../Assets/Icons/invoices.svg';


export default function Aside({ mobWidth }) {
    const { Permissions } = useContext(PermissionsContext);
    let closeRef = useRef();
    function closeOffCanvas() {
        if (closeRef.current.style.display === 'none') {
            closeRef.current.click()
        }
    }
    const navigate = useNavigate()

    return (
        <>
            <div className='asideWrapper p-0' style={{ width: `${mobWidth}%` }} >
                <div className='d-flex justify-content-center'>
                    <img className='w-50 fa-beat' style={{ "--fa-animation-duration": "3s", "--fa-beat-scale": "1.075" }} src={logo} alt="logo" />
                    <button ref={closeRef} type="button" className="btn-close text-reset sideBarClose px-4 d-lg-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <ul className='asideList'>
                    <li>
                        <NavLink className={`navLink`} to={`${basename}/dashboard/overview`} onClick={closeOffCanvas}>
                            <img src={overview} alt="icon" />
                            <span className='capitalWord'>{t('overview')}</span>
                        </NavLink>
                    </li>
                    {Permissions.contact?.view &&
                        <li>
                            <NavLink className={`navLink`} to={`${basename}/dashboard/contactus`} onClick={closeOffCanvas}>
                                <img src={contact} alt="icon" />
                                <span className='capitalWord'>{t('contact-us')}</span>
                            </NavLink>
                        </li>
                    }
                </ul>
                <div className='asideSplitter'></div>
                <ul className='asideList'>
                    {/* user types */}
                    <li>
                        <div className={`navLink`} onClick={() => Permissions.user?.view && navigate(`${basename}/dashboard/app-users/users`)} style={{ cursor: "pointer" }} data-bs-toggle="collapse" data-bs-target="#appUsersCollapse" >
                            <img src={app_users} alt="icon" />
                            <span className='capitalWord'>{t('app-users')}</span>
                        </div>
                        <ul className="collapse asideDropdown" id='appUsersCollapse'>

                            {Permissions.club?.view && <li>
                                <NavLink to={`${basename}/dashboard/app-users/clubs?status=active`} className="nestedNavLink" onClick={closeOffCanvas}>{t('clubs')}</NavLink>
                            </li>}
                            {Permissions.academy?.view && <li>
                                <NavLink to={`${basename}/dashboard/app-users/academies?status=pending`} className="nestedNavLink" onClick={closeOffCanvas}>{t('academies')}</NavLink>
                            </li>}
                            {Permissions.player?.view && <li>
                                <NavLink to={`${basename}/dashboard/app-users/players?status=active`} className="nestedNavLink" onClick={closeOffCanvas}>{t('players')}</NavLink>
                            </li>}
                            {Permissions.trainer?.view && <li>
                                <NavLink to={`${basename}/dashboard/app-users/trainers?status=active`} className="nestedNavLink" onClick={closeOffCanvas}>{t('trainers')}</NavLink>
                            </li>}
                            {Permissions.journalist?.view && <li>
                                <NavLink to={`${basename}/dashboard/app-users/journalists?status=active`} className="nestedNavLink" onClick={closeOffCanvas}>{t('journalists')}</NavLink>
                            </li>}
                            {Permissions.business?.view && <li>
                                <NavLink to={`${basename}/dashboard/app-users/businesses?status=active`} className="nestedNavLink" onClick={closeOffCanvas}>{t('businesses')}</NavLink>
                            </li>}
                            {Permissions.federation?.view && <li>
                                <NavLink to={`${basename}/dashboard/app-users/federations?status=active`} className="nestedNavLink" onClick={closeOffCanvas}>{t('federations')}</NavLink>
                            </li>}
                            {Permissions.influencer?.view && <li>
                                <NavLink to={`${basename}/dashboard/app-users/influencers?status=active`} className="nestedNavLink" onClick={closeOffCanvas}>{t('influencers')}</NavLink>
                            </li>}
                            {Permissions.fan?.view && <li>
                                <NavLink to={`${basename}/dashboard/app-users/fans?status=active`} className="nestedNavLink" onClick={closeOffCanvas}>{t('fans')}</NavLink>
                            </li>}
                        </ul>
                    </li>

                    <li>
                        {(Permissions?.admin?.view || Permissions?.role?.view) &&
                            <>
                                <NavLink className={`navLink`} to={`${basename}/dashboard/admins`} data-bs-toggle="collapse" data-bs-target="#adminsCollapse">
                                    <img src={admins} alt="icon" />
                                    <span className='capitalWord'>{t('admins')}</span>
                                </NavLink>

                                <ul className="collapse asideDropdown" id='adminsCollapse'>

                                    {Permissions?.admin?.view && <li>
                                        <NavLink to={`${basename}/dashboard/admins`} className="nestedNavLink" onClick={closeOffCanvas}>{t('admins-list')}</NavLink>
                                    </li>}

                                    {Permissions?.role?.view && <li>
                                        <NavLink to={`${basename}/dashboard/admins/roles/add`} className="nestedNavLink" onClick={closeOffCanvas}>{t('roles')}</NavLink>
                                    </li>}
                                </ul>
                            </>
                        }
                    </li>
                    <li>
                         {/***************U need To change Delete req Permissions ************* */}

                         {Permissions.spam?.view && <NavLink className={`navLink`} to={`${basename}/dashboard/deletedreq`} onClick={closeOffCanvas}>
                            <img src={deleteUser} alt="icon" />
                            <span className='capitalWord'>{t('delete-requests')}</span>
                        </NavLink>}
                    </li>

                </ul>

                <div className='asideSplitter'></div>
                <ul className='asideList'>
                    
                    {/* invoices */}
                    {Permissions.invoice?.view && <NavLink className={`navLink`} to={`${basename}/dashboard/invoices`} onClick={closeOffCanvas}>
                        <img src={invoices} alt="icon" />
                        <span className='capitalWord'>{t('invoices')}</span>
                    </NavLink>}
                   

                    {/* player positions */}
                    <li>
                        {Permissions.spam?.view && <NavLink className={`navLink`} to={`${basename}/dashboard/spams`} onClick={closeOffCanvas}>
                            <img src={spam} alt="icon" />
                            <span className='capitalWord'>{t('spams')}</span>
                        </NavLink>}
                        {Permissions.report?.view && <NavLink className={`navLink`} to={`${basename}/dashboard/reports`} onClick={closeOffCanvas}>
                            <img src={report} alt="icon" />
                            <span className='capitalWord'>{t('reports')}</span>
                        </NavLink>}
                    </li>
                    {(Permissions.spam?.view || Permissions.report?.view || Permissions.blog?.view) && <div className='asideSplitter'></div>}
                    <li>
                        {Permissions.promote?.view &&
                            <NavLink className={`navLink`} to={`${basename}/dashboard/promotes`} onClick={closeOffCanvas}>
                                <img src={pro} alt="icon" />
                                <span className='capitalWord'>{t('promoted-videos')}</span>
                            </NavLink>}
                        {Permissions.sound?.view && <NavLink className={`navLink`} to={`${basename}/dashboard/sound`} onClick={closeOffCanvas}>
                            <img src={ved} alt="icon" />
                            <span className='capitalWord'>{t('sounds')}</span>
                        </NavLink>}
                        {Permissions.trainer?.view && <NavLink className={`navLink`} to={`${basename}/dashboard/courses`} onClick={closeOffCanvas}>
                            <img src={courses} alt="icon" />
                            <span className='capitalWord'>{t('courses')}</span>
                        </NavLink>}
                        {/* Stickers */}
                        {Permissions.sticker?.view && <NavLink className={`navLink`} to={`${basename}/dashboard/stickers`} onClick={closeOffCanvas}>
                            <img src={stickers} alt="icon" />
                            <span className='capitalWord'>{t('stickers')}</span>
                        </NavLink>}

                    </li>
                    <li>
                        {(Permissions.blog?.view || Permissions.tag?.view) &&
                            <NavLink className={`navLink`} data-bs-toggle="collapse" data-bs-target="#articlesCollapse" to={`${basename}/dashboard/articles`}>
                                <img src={blogs} alt="icon" />
                                <span className='capitalWord'>{t('articles')}</span>
                            </NavLink>}
                        <ul className="collapse asideDropdown" id='articlesCollapse'>
                            {Permissions.blog?.view &&
                                <li>
                                    <NavLink to={`${basename}/dashboard/articles`} end={true} className="nestedNavLink" onClick={closeOffCanvas} >{t('all-articles')}</NavLink>
                                </li>}
                            {Permissions.tag?.view &&
                                <li>
                                    <NavLink to={`${basename}/dashboard/articles/tags`} className="nestedNavLink" onClick={closeOffCanvas}>{t('tags')}</NavLink>
                                </li>}
                        </ul>
                    </li>

                    <li>
                        {(Permissions['trainer-experience-level']?.view || Permissions['academy-level']?.view) &&
                            <NavLink className={`navLink`} to={`${basename}/dashboard/levels`} data-bs-toggle="collapse" data-bs-target="#sectionsCollapse">
                                <img src={levels} alt="icon" />
                                <span className='capitalWord'>{t('levels')}</span>
                            </NavLink>}
                        <ul className="collapse asideDropdown" id='sectionsCollapse'>
                            {Permissions['academy-level']?.view && <li>
                                <NavLink to={`${basename}/dashboard/levels/academy`} className="nestedNavLink" onClick={closeOffCanvas}>{t('academy-levels')}</NavLink>
                            </li>}
                            {Permissions['trainer-experience-level']?.view && <li>
                                <NavLink to={`${basename}/dashboard/levels/trainer`} className="nestedNavLink" onClick={closeOffCanvas}>{t('trainer-levels')}</NavLink>
                            </li>}
                        </ul>
                    </li>
                    {/* player positions */}
                    {Permissions['player-footnesse']?.view && <li>
                        <NavLink className={`navLink`} to={`${basename}/dashboard/player-positions`} onClick={closeOffCanvas}>
                            <img src={positions} alt="icon" />
                            <span className='capitalWord'>{t('playing-positions')}</span>
                        </NavLink>
                    </li>}
                    {/* foots */}
                    {Permissions['player-footnesse']?.view && <li>
                        <NavLink className={`navLink`} to={`${basename}/dashboard/player-foots`} onClick={closeOffCanvas}>
                            <img src={foots} alt="icon" />
                            <span className='capitalWord'>{t('player-foots')}</span>
                        </NavLink>
                    </li>}
                </ul>
                {(Permissions['player-footnesse']?.view || Permissions['player-footnesse']?.view || Permissions.sound?.view || Permissions.trainer?.view || Permissions.sticker?.view)
                    && <div className='asideSplitter'></div>}
                <ul className='asideList'>
                    {Permissions.setting?.view && <li>
                        <NavLink className={`navLink`} to={`${basename}/dashboard/financials`} onClick={closeOffCanvas}>
                            <img src={fee} alt="icon" />
                            <span className='capitalWord'>{t('financials')}</span>
                        </NavLink>
                    </li>}
                </ul>
                {Permissions.setting?.view && <div className='asideSplitter'></div>}

                {/* settings */}
                <ul className='asideList'>

                    <li>
                        <NavLink className={`navLink`} data-bs-toggle="collapse" data-bs-target="#settingsCollapse" to={`${basename}/dashboard/settings`}>
                            <img src={settings} alt="icon" />
                            <span className='capitalWord'>{t('settings')}</span>
                        </NavLink>
                        <ul className="collapse asideDropdown" id='settingsCollapse'>
                            {Permissions.setting?.view && <li>
                                <NavLink to={`${basename}/dashboard/settings/appearance`} className="nestedNavLink" onClick={closeOffCanvas} >{t('appearance')}</NavLink>
                            </li>}
                            <li>
                                <NavLink to={`${basename}/dashboard/settings/security`} className="nestedNavLink" onClick={closeOffCanvas}>{t('security')}</NavLink>
                            </li>
                            <li>
                                <NavLink to={`${basename}/dashboard/settings/account`} className="nestedNavLink" onClick={closeOffCanvas}>{t('account')}</NavLink>
                            </li>
                            {/* {Permissions.setting?.view && <li>
                                <NavLink to={`${basename}/dashboard/settings/currency`} className="nestedNavLink" onClick={closeOffCanvas}>{t('currency')}</NavLink>
                            </li>} */}
                            {Permissions.setting?.view && <li>
                                <NavLink to={`${basename}/dashboard/settings/countries`} className="nestedNavLink" onClick={closeOffCanvas}>{t('countries')}</NavLink>
                            </li>}
                            {Permissions.setting?.view && <li>
                                <NavLink to={`${basename}/dashboard/settings/cities`} className="nestedNavLink lastOne" onClick={closeOffCanvas}>{t('cities')}</NavLink>
                            </li>}
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    )
}
